<template>
  <div>
    <page-edit-fields :record="record">
      <template slot="meta">
        <v-textarea
            filled rows="3"
            label="Titre dans la page"
            placeholder="Titre de page"
            hide-details
            v-model="record.pagetitle"/>
      </template>
      <template slot="poster">
        <poster-options :record="record" />
      </template>
      <template slot="blocks">
        <blocks-field
            label=""
            :field="record.blocks"/>
      </template>

    </page-edit-fields>

    <code-preview
        title="Données"
        :code="this.record"
        class="my-5"
    />

  </div>
</template>

<script>

import CodePreview from "../ee09/db-ui/components/code-preview";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import BlocksField from "@/ee09/db-ui/fields/blocks-field";
import PageEditFields from "@/Models/edit-shared/page-edit-fields";
import PosterOptions from "@/Models/edit-shared/poster-options";


export default {
  name: "page-edit",
  components: {
    PosterOptions,
    PageEditFields,
    BlocksField, CodePreview},
  mixins:[RecordEditMixin],
  data(){
    return {

      }
  },
  props:{
    record:{
      type:Object
    }
  }
}
</script>

