<template>
<div>
  <image-field :field="obj.image" label="Image"/>
</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import ImageField from "@/ee09/db-ui/fields/image-field";

export default {
  name: "media-block-edit",
  components: {ImageField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {MediaBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
