<template>
<div>
  <link-item-field
      label="Lien principal"
      :field="obj.link"
  />
  <v-switch v-model="obj.automaticList"
  label="Liste automatique"
  />
  <records-field
      v-if="!obj.automaticList"
      label="Métiers à afficher dans la liste"
      placeholder="Trouver un métier à ajouter"
      :field="obj.records"
      :record-types="['metier']"
  />
</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import RecordsField from "@/ee09/db-ui/fields/records-field";
import LinkItemField from "@/ee09/db-ui/fields/link-item-field";

export default {
  name: "metiers-block-edit",
  components: {LinkItemField, RecordsField},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {MetiersBlock}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
