<template>
  <div>
    <v-row>
      <v-col>
        <v-switch
            v-if="formationsPossible"
            dense
            label="Menu formations"
            v-model="recordObject.extravars.posterMenuFormations"
        />
      </v-col>
    </v-row>
    <v-textarea
        label="Texte du poster"
        filled auto-grow
        v-if="notificationsPossible"
        v-model="recordObject.extravars.posterText"
    />
    <div v-if="notificationsPossible">
      <field-label>Notifications du poster</field-label>
    </div>

</div>
</template>

<script>
import FieldLabel from "@/ee09/db-ui/fields/field-label";
import RecordEditMixin from "@/ee09/db-ui/records/RecordEditMixin";
import PosterOptionsMixin from "@/Models/edit-shared/PosterOptionsMixin";
export default {
  name: "poster-options",
  components: {FieldLabel},
  mixins:[RecordEditMixin,PosterOptionsMixin],
  computed:{
    /**
     *
     * @return {PageModel}
     */
    recordObject(){
      return this.record;
    }

  }
}
</script>

<style scoped>

</style>